.privacy-and-terms {
  h1 {
    margin-bottom: 24px;
  }

  h3 {
    margin-bottom: 16px;
    font-family: Aeonik Regular;
  }

  p {
    margin-bottom: 16px;
    color: $black;
  }

  a {
    color: $regent-gray;

    &:active,
    &:focus,
    &:hover {
      color: $black;
    }
  }

  .nav {
    margin: 40px 0 24px 0;

    .nav-item:first-child {
      margin-right: 16px;
    }

    .nav-link {
      padding: 3px 18px;
      color: $violet;
      background-color: $hawkes-blue;
      border-radius: 24px;
      font-family: Aeonik Medium;
      font-size: $font-size-medium;
      line-height: 24px;
      text-align: center;

      &.active {
        color: $white;
        background-color: $royal-blue;
      }
    }
  }
}

@media screen and (min-width: 992px) {
  .privacy-and-terms {
    margin-bottom: 136px;

    .container {
      max-width: 820px;
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .privacy-and-terms {
    margin-bottom: 96px;
  }
}

@media screen and (max-width: 767px) {
  .privacy-and-terms {
    margin-bottom: 40px;

    h1 {
      line-height: 32px;
    }

    h3 {
      font-size: $font-size-extra-large;
      line-height: 32px;
    }

    .nav {
      margin-bottom: 32px;
    }
  }
}