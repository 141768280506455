.home {
  .landing-hero-section {
    align-items: center;
    position: relative;
    background: linear-gradient(180deg, $white 0%, $zircon 100%);
  }

  .features-section {
    img {
      display: block;
      margin: 0 auto 8px auto;
      width: 71px;
      height: 93px;
    }

    h3 {
      margin-bottom: 8px;
      color: $violet;
      font-family: Aeonik Medium;
    }

    a {
      margin: 40px auto 0 auto;
    }
  }

  .map-section {
    p {
      margin-bottom: 0;
      font-family: Aeonik Light;
      font-size: 22px;
      line-height: 30px;
      letter-spacing: .2px;
    }

    .map-pins {

      .pin-1,
      .pin-2,
      .pin-3,
      .pin-4,
      .pin-5,
      .pin-6 {
        position: absolute;
        background-color: $royal-blue;
        border-radius: 50%;
        transform: scale(0);
        transition: all .3s ease-in-out;
      }
    }
  }

  .pricing-section {
    background: linear-gradient(180deg, $white 0%, $zircon 100%);

    .pricing-content {
      h2 {
        margin-bottom: 20px;
      }

      h3 {
        margin-bottom: 40px;
        font-family: Aeonik Regular;
        font-size: $font-size-extreme-large;
        line-height: 40px;
      }

      p {
        margin-bottom: 8px;
        font-size: $font-size-small;
      }
    }

    .pricing-illustrations {
      margin: 0 auto 40px auto;
    }

    .pricing-project-manager {
      left: 144px;
      right: 111px;
      width: 245px;
      height: 160px;
    }

    .pricing-travel-agent {
      top: 74px;
      bottom: 64px;
      width: 244px;
      height: 179px;
    }

    .pricing-backend {
      width: 244px;
      height: 167px;
    }

    .pricing-calculator {
      margin: 0 auto;
    }

    .pricing-text {
      margin-bottom: 12px;
      text-align: center;

      p {
        display: inline-block;
        margin-bottom: 0;
        font-size: $font-size-small;
        line-height: 16px;
      }
    }

    .pricing-monthly,
    .pricing-total {
      margin: 0 6px;
      font-family: Aeonik Medium;
      color: $violet;
      line-height: 24px;
    }

    .pricing-monthly {
      font-size: $font-size-small;
    }

    .pricing-total {
      font-size: $font-size-large;
    }

    h2 {
      margin-bottom: 40px;
    }
  }

  .faq-section {
    .faq {
      margin: 0;
    }

    h2 {
      margin: 0 0 32px 0;
      line-height: 32px;
    }

    #accordion {
      margin-bottom: 32px;
    }

    .card {
      border: 0;
      border-radius: 0;

      &:last-child {
        border-bottom: 1px solid $athens-gray;
      }

      .show {
        border-bottom: 1px solid $royal-blue;
      }
    }

    .card-header {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0;
      background-color: transparent;
      border: 0;

      button {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        position: relative;
        padding: 24px 0;
        width: 100%;
        color: $royal-blue;
        border: 0;
        border-top: 1px solid $royal-blue;
        border-radius: 0;
        font-size: $font-size-large;
        line-height: 24px;
        text-align: left;

        &.collapsed {
          color: $violet;
          border-top: 1px solid $athens-gray;

          .button-accordion-icon {
            background: url(./../../assets/icons/open.svg) no-repeat center;
          }
        }
      }
    }

    .button-accordion {
      position: relative;
      margin-left: 16px;
      width: 14px;
      height: 14px;

      &-icon {
        display: block;
        width: 14px;
        height: 14px;
        background: url(./../../assets/icons/close.svg) no-repeat center;
      }
    }

    .card-body {
      padding: 0;
      color: $regent-gray;
      font-size: $font-size-large;
      line-height: 24px;
    }

    a {
      color: $royal-blue;
      font-family: Aeonik Medium;
      font-size: $font-size-large;
      line-height: 24px;
    }

    img {
      position: absolute;
      right: 0;
      bottom: 0;
      transform: translate(0, 15%);
    }

    p {
      margin-bottom: 24px;
    }
  }

  .plug-app-section {
    background-color: $zircon;

    img {
      position: relative;
      bottom: 23px;
    }

    .col-12:nth-child(2) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
    }

    h2 {
      margin-bottom: 48px;
      max-width: 480px;
    }
  }
}

@media screen and (min-width: 1200px) {
  .home {
    .map-section .map-pins {
      .pin-1 {
        top: 138px;
        left: 97px;
      }

      .pin-2 {
        top: 130px;
        left: 192px;
      }

      .pin-3 {
        top: 246px;
        left: 237px;
      }

      .pin-4 {
        top: 94px;
        left: 344px;
      }

      .pin-5 {
        top: 105px;
        left: 584px;
      }

      .pin-6 {
        top: 277px;
        left: 633px;
      }
    }

    .faq-section .faq {
      max-width: 713px;
    }

    .plug-app-section img {
      width: 467px;
    }
  }
}

@media screen and (max-width: 1199px) {
  .home {
    .landing-hero-section img {
      width: 536px;
    }

    .plug-app-section img {
      width: 100%;
    }
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .home {
    .map-section {
      .map-pins {
        .pin-1 {
          top: 110px;
          left: 75px;
        }

        .pin-2 {
          top: 95px;
          left: 143px;
        }

        .pin-3 {
          top: 186px;
          left: 181px;
        }

        .pin-4 {
          top: 82px;
          left: 254px;
        }

        .pin-5 {
          top: 77px;
          left: 432px;
        }

        .pin-6 {
          top: 207px;
          left: 468px;
        }
      }

      img {
        width: 530px;
      }
    }

    .faq-section .faq {
      max-width: 550px;
    }
  }
}

@media screen and (min-width: 992px) {
  .home {

    .map-section,
    .pricing-section,
    .plug-app-section {
      h2 {
        font-size: $font-size-mega-large;
        line-height: 56px;
      }
    }

    .landing-hero-section {
      padding: 55px 0 238px 0;
    }

    .map-section {
      margin-bottom: 70px;
      padding: 90px 0 112px 0;

      .map-text {
        max-width: 502px;
      }

      .map-pins {
        position: absolute;
        right: 0;
        transform: translate(0, -70%);
      }
    }

    .pricing-section {
      padding-bottom: 80px;

      .pricing-content {
        margin: 40px 0 0 101px;
        width: 340px;
      }

      .pricing-calculator {
        margin-top: 80px;
        max-width: 607px;
      }
    }

    .faq-section {
      margin: 83px 0 153px 0;
    }
  }
}

@media screen and (max-width: 991px) {
  .home {
    .map-section {
      p {
        font-size: $font-size-large;
        line-height: 24px;
      }

      .map-pins {
        position: relative;
      }

      img {
        width: 100%;
      }
    }

    .pricing-section {
      padding-bottom: 40px;

      .pricing-calculator {
        text-align: center;
      }

      .btn {
        margin-top: 28px;
      }
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .home {
    .landing-hero-section {
      padding-bottom: 360px;
    }

    .map-section {
      padding-bottom: 80px;

      .map-text {
        margin: 0 auto 50px auto;
        max-width: 440px;
        text-align: center;
      }

      .map-pins {
        .pin-1 {
          top: 138px;
          left: 97px;
        }

        .pin-2 {
          top: 124px;
          left: 187px;
        }

        .pin-3 {
          top: 247px;
          left: 239px;
        }

        .pin-4 {
          top: 102px;
          left: 333px;
        }

        .pin-5 {
          top: 99px;
          left: 564px;
        }

        .pin-6 {
          top: 270px;
          left: 612px;
        }
      }
    }

    .pricing-section {
      overflow: hidden;

      .pricing-illustrations {
        margin: 25px auto 25px -100px;
      }
    }

    .faq-section {
      margin: 43px 0 67px 0;
    }

    .plug-app-section {
      h2 {
        margin-bottom: 40px;
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .home {
    h1 {
      margin-bottom: 48px;
      font-size: 80px;
      line-height: 88px;
    }

    .landing-hero-section {
      .landing-hero-text {
        max-width: 653px;
      }

      img {
        position: absolute;
        bottom: 0;
        right: 0;
      }
    }

    .features-section {
      margin: 59px 0 80px 0;
      text-align: center;
    }

    .pricing-section .pricing-illustrations {
      width: 500px;
      height: 317px;
    }

    .map-section {
      .map-pins {

        .pin-1,
        .pin-2,
        .pin-3,
        .pin-5,
        .pin-6 {
          width: 8px;
          height: 8px;
        }

        .pin-1,
        .pin-3 {
          box-shadow: 0 0 1px 4px rgba($melrose, .24),
            0 0 1px 8px rgba($melrose, .24);
        }

        .pin-4 {
          width: 24px;
          height: 24px;
          box-shadow: 0 0 1px 8px rgba($melrose, .24),
            0 0 1px 16px rgba($melrose, .24);
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .home {
    h1 {
      margin-bottom: 22px;
    }

    .map-section,
    .pricing-section,
    .plug-app-section {
      h2 {
        font-size: $font-size-extreme-large;
        line-height: 32px;
      }
    }

    .landing-hero-section {
      text-align: center;

      .landing-hero-text {
        padding-bottom: 24px;
      }

      img {
        width: 100%;
      }
    }

    .features-section {
      margin: 12px 0 16px 0;

      img {
        margin: 0 0 8px 0;
      }

      a {
        margin-top: 34px;
      }
    }

    .map-section {
      padding: 32px 0 38px 0;

      .map-text {
        margin: 16px 0 32px auto;
      }

      .map-pins {

        .pin-1,
        .pin-2,
        .pin-3,
        .pin-5,
        .pin-6 {
          width: 6px;
          height: 6px;
        }

        .pin-1,
        .pin-3 {
          box-shadow: 0 0 1px 3px rgba($melrose, .24),
            0 0 1px 6px rgba($melrose, .24);
        }

        .pin-1 {
          top: 40%;
          left: 14%;
        }

        .pin-2 {
          top: 37%;
          left: 27%;
        }

        .pin-3 {
          top: 73%;
          left: 34%;
        }

        .pin-4 {
          top: 27%;
          left: 48%;
          width: 16px;
          height: 16px;
          box-shadow: 0 0 1px 4px rgba($melrose, .24),
            0 0 1px 8px rgba($melrose, .24);
        }

        .pin-5 {
          top: 30%;
          left: 81%;
        }

        .pin-6 {
          top: 80%;
          left: 88%;
        }
      }
    }

    .pricing-section {
      .pricing-illustrations {
        margin-bottom: 24px;
        width: 300px;
        height: 191px;
      }

      .pricing-project-manager {
        left: 86px;
        right: 67px;
        width: 147px;
        height: 96px;
      }

      .pricing-travel-agent {
        top: 44px;
        bottom: 39px;
        width: 146px;
        height: 107px;
      }

      .pricing-backend {
        width: 146px;
        height: 100px;
      }
    }

    .faq-section {
      margin: 40px 0 50px 0;

      h2 {
        margin: 0 0 46px 0;
      }
    }

    .plug-app-section {
      margin-bottom: 40px;

      h2 {
        margin: 0 auto 24px auto;
        text-align: center;
      }
    }
  }
}