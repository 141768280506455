.about {
  h1 {
    text-align: center;
  }

  h3 {
    font-family: Aeonik Regular;
    text-align: center;
  }

  .nav-link {
    color: $gray;
    border-left: 2px solid $mischka;
    border-radius: 0;

    &.active {
      color: $violet;
      background-color: transparent;
      border-left: 2px solid $royal-blue;
    }
  }

  .tab-pane {
    color: $black;
    font-size: $font-size-large;
    line-height: 24px;
  }

  .plug-app-section {
    background-color: $zircon;

    .col-12:nth-child(2) {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    img {
      position: absolute;
    }

    h2 {
      max-width: 480px;
    }

    a {
      margin: 0 auto 0 0;
    }
  }
}

@media screen and (min-width: 1200px) {
  .about {
    .plug-app-section img {
      max-width: 492px;
      bottom: -50px;
    }
  }
}

@media screen and (min-width: 992px) {
  .about {
    .info-section {
      margin-bottom: 120px;
    }

    .nav-link {
      font-size: $font-size-extra-large;
      line-height: 32px;
    }

    .plug-app-section {
      padding: 92px 0 80px 0;

      h2 {
        margin-bottom: 48px;
        font-size: $font-size-mega-large;
        line-height: 56px;
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .about {
    h3 {
      font-size: $font-size-extra-large;
      line-height: 32px;
    }

    .info-section {
      margin-bottom: 64px;
    }

    .nav-link {
      font-size: $font-size-large;
      line-height: 24px;
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1199px) {
  .about {
    .plug-app-section img {
      max-width: 335px;
      bottom: -20px;
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .about {
    .plug-app-section {
      padding: 58px 0 78px 0;

      h2 {
        margin-bottom: 40px;
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .about {
    h1 {
      margin-bottom: 16px;
    }

    h3 {
      margin-bottom: 80px;
    }
  }
}

@media screen and (max-width: 767px) {
  .about {
    h1 {
      margin-bottom: 8px;
    }

    h3 {
      margin-bottom: 40px;
    }

    .nav {
      margin-bottom: 40px;
    }

    .plug-app-section {
      margin-bottom: 40px;
      text-align: center;

      img {
        position: relative;
        bottom: 20px;
        max-width: 272px;
      }

      h2 {
        margin: 0 auto 24px auto;
        max-width: 288px;
      }
    }
  }
}