.privacy {
  .wrapper {
    position: relative;

    h3 {
      padding-top: 16px;
    }

    h5 {
      margin-bottom: 4px;
      font-family: Aeonik Medium;
      font-size: $font-size-small;
      line-height: 24px;
    }

    ul {
      margin: 0 0 16px 36px;
      font-size: $font-size-large;
    }

    >div {
      position: relative;
    }

    .short-summary {
      position: absolute;
      padding: 24px;
      top: 0;
      color: $black;
      background-color: $titan-white;
      border-radius: 8px;
      font-size: $font-size-small;
      line-height: 16px;

      ::before {
        content: '';
        position: absolute;
        top: 30px;
        left: -7px;
        width: 14px;
        height: 14px;
        background-color: $titan-white;
        transform: rotate(45deg);
      }
    }
  }
}

@media screen and (min-width: 992px) {
  .privacy {
    .wrapper {
      max-width: 503px;

      .short-summary {
        width: 292px;
        left: 533px;
      }
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .privacy {
    .wrapper {
      max-width: 474px;

      h3 {
        font-size: $font-size-extra-large;
        line-height: 32px;
      }

      .short-summary {
        width: 239px;
        left: 490px;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .privacy {
    .wrapper {
      ul {
        margin: 0 23px 16px 0;
      }

      .short-summary {
        position: relative;
        margin: 26px 0;
        padding: 22px 16px;
        left: 0;
        width: 100%;
        height: auto;

        ::before {
          display: none;
        }
      }
    }
  }
}