// White color
$white: #fff;
$titan-white: #e9ecff;

// Black color
$black: #000;
$thunder: #231f20;

// Gray color
$gray: #939393;
$regent-gray: #89929f;
$mischka: #dbdbe8;
$athens-gray: #e3e5e8;
$ghost: #c4c8cf;
$alto: #d8d8d8;

// Blue color
$violet: #0a0d35;
$royal-blue: #515cf0;
$cornflower-blue: #6872f2;
$persian-blue: #2230ec;
$hawkes-blue: #eceffe;
$zircon: #fafcff;
$spindle: #b6b9ea;
$melrose: #b2aefe;
$portage: #8186f6;

// Red color
$romantic: #ffcfb0;
$light-apricot: #fddac3;
$peach-orange: #fdbf97;
$crimson: #da1934;