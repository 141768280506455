.footer {
  .logo {
    display: inline-block;
    width: 168px;
    height: 32px;
  }

  .social-media {
    margin-bottom: 16px;

    img {
      margin-right: 4px;
      width: 32px;
      height: 32px;
    }
  }

  .copyright {
    color: $regent-gray;
    font-size: $font-size-small;
    line-height: 16px;
  }

  h4 {
    color: $regent-gray;
  }

  li {
    border: none;
    padding: 8px 0;
    color: $regent-gray;
    background-color: transparent;
    font-size: $font-size-small;
    line-height: 16px;
  }

  a {
    color: $regent-gray;

    &:active,
    &:focus,
    &:hover {
      color: $black;
    }
  }
}

@media screen and (min-width: 992px) {
  .footer {
    .container {
      padding-top: 80px;
      padding-bottom: 80px;
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .footer {
    .container {
      padding-top: 40px;
      padding-bottom: 40px;
    }
  }
}

@media screen and (min-width: 768px) {
  .footer {
    .logo {
      margin-bottom: 64px;
    }
  }
}

@media screen and (max-width: 767px) {
  .footer {
    .container {
      padding-top: 24px;
      padding-bottom: 70px;
    }

    .col-6 {
      margin-bottom: 24px;
    }

    .logo {
      margin-bottom: 24px;
    }

    .social-media {
      margin-top: 16px;
    }
  }
}