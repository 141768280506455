.contact {
  .row {
    position: relative;
  }

  &-box {
    width: 100%;
    max-width: 397px;
    background-color: $white;
    border-radius: 24px;
    box-shadow: 0 20px 40px 0 rgba(10, 13, 53, .08);
  }

  &-form {
    h2 {
      margin-bottom: 16px;
      line-height: 32px;
    }

    p {
      margin-bottom: 24px;
    }

    label {
      margin-bottom: 0;
      color: $regent-gray;
      font-size: 12px;
      line-height: 16px;
    }

    input {
      margin-bottom: 16px;
      height: 28px;
    }

    textarea {
      margin-bottom: 32px;
      height: 75px;
    }

    input,
    textarea {
      padding: 0;
      color: $black !important;
      border: 0;
      border-bottom: 1px solid $ghost !important;
      border-radius: 0;
      font-size: $font-size-large;
      line-height: 24px;
    }
  }

  &-success-box {
    display: none;

    img {
      display: block;
      margin: 23px auto 38px auto;
      width: 130px;
      height: 171px;
    }

    h2 {
      text-align: center;
    }

    h3 {
      margin-bottom: 72px;
      font-size: $font-size-extra-large;
      text-align: center;
    }

    h4 {
      display: none;
      margin-bottom: 72px;
      text-align: center;
    }
  }

  &-error-box {
    display: none;

    h3 {
      margin: 50px auto 72px auto;
      color: $crimson;
      text-align: center;
    }
  }

  .newsletter {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 24px;

    p {
      margin-bottom: 0;
      font-size: $font-size-small;
    }
  }

  .is-invalid {
    margin-bottom: 8px;
    border-bottom: 1px solid $crimson !important;
    background: none;
  }

  .alert {
    display: none;
    margin-bottom: 16px;
    padding: 0;
    font-size: 12px;
    line-height: 16px;

    &-danger {
      color: $crimson;
      border: none;
      background-color: $white;
    }
  }

  .spinner-border {
    display: none;
    margin-right: 5px;
  }

  &-info {
    .col-12 {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
    }

    img {
      margin: 3px 16px 0 0;
    }

    h5 {
      margin-bottom: 0;
      font-size: $font-size-large;
      line-height: 24px;
    }

    a {
      color: $regent-gray;
      font-size: $font-size-large;
      line-height: 24px;

      &:active,
      &:focus,
      &:hover {
        color: $black;
      }
    }

    .contact-email a:nth-child(2) {
      display: inline-block;
      margin-bottom: 16px;
    }
  }
}

@media screen and (min-width: 992px) {
  .contact {
    &-form-section {
      margin-bottom: 103px;
    }
  }
}

@media screen and (max-width: 991px) {
  .contact {
    &-info .col-12 {
      margin-bottom: 24px;
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .contact {
    &-form-section {
      margin-bottom: 82px;
    }

    &-box {
      margin: 0 auto;
    }
  }
}

@media screen and (min-width: 768px) {
  .contact {
    margin-bottom: 56px;

    .row .map-bg {
      position: absolute;
      right: 0;
      z-index: -1;
    }

    &-box {
      padding: 32px;
    }
  }
}

@media screen and (max-width: 767px) {
  .contact {
    margin-bottom: 16px;

    &-form-section {
      margin-bottom: 36px;
    }

    .row .map-bg {
      max-width: 100%;
    }

    &-box {
      margin: 0 16px 16px 16px;
      padding: 16px;
    }

    &-form {
      h2 {
        font-size: $font-size-extreme-large;
      }
    }
  }
}