.media-kit {
  h1 {
    margin-bottom: 16px;
  }

  h3 {
    font-family: Aeonik Regular;
    margin-bottom: 80px;
  }

  h4 {
    font-family: Aeonik Medium;
  }

  .favourite-allmyles-stories,
  .describing-allmyles,
  .photo-assets {
    &-section {
      margin-bottom: 80px;
    }
  }

  .brand-assets-section {
    margin-bottom: 40px;
  }

  .favourite-allmyles-stories,
  .describing-allmyles {
    &-section {
      h2 {
        margin-bottom: 24px;
      }
    }
  }

  .brand-assets,
  .photo-assets {
    &-section {
      h2 {
        margin-bottom: 16px;
      }

      h4 {
        margin-bottom: 16px;
      }
    }
  }

  .favourite-allmyles-stories-section {
    .col-12 {
      padding-left: 12px;
      padding-right: 12px;
    }

    .card {
      align-items: flex-start;
      padding: 48px 32px 40px 40px;
      border: none;
      height: 192px;

      img {
        margin-bottom: 16px;
        height: 40px;
      }

      &:hover {
        border-radius: 24px;
        box-shadow: 0 20px 40px 0 rgba(10, 13, 53, .08);
        cursor: pointer;
      }
    }

    p {
      font-family: Aeonik Medium;
      color: $violet;
    }
  }

  .describing-allmyles-section {
    h4 {
      margin-bottom: 8px;
    }

    p {
      max-width: 503px;
    }
  }

  #download-logos-button {
    margin-top: 16px;
    float: right;
  }

  #download-photos-button {
    margin-top: 64px;
    float: right;
  }

  .image {
    &-background {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      height: 168px;
      border-radius: 8px;
      box-shadow: 0 20px 40px 0 rgba(10, 13, 53, .08);

      &--color {
        &-white {
          background-color: $white;
        }

        &-royal-blue {
          background-color: $royal-blue;
        }
      }
    }
  }

  .photo-assets-section {
    p {
      margin-bottom: 40px;
    }

    .col-12 {
      padding-left: 12px;
      padding-right: 12px;
    }

    .card {
      border: none;
      border-radius: 6px;
    }

    img {
      margin-bottom: 15px;
      max-width: 100%;
    }
  }
}

@media screen and (max-width: 1199px) {
  .media-kit {
    .favourite-allmyles-stories-section {
      .container:nth-child(2) {
        max-width: 100%;
      }

      .cards {
        display: flex;
        flex-wrap: nowrap;
        padding-bottom: 68px;
        padding-left: 8px;
        padding-right: 8px;
        overflow-x: auto;
        scrollbar-width: none;
        -ms-overflow-style: none;

        &::-webkit-scrollbar {
          display: none;
        }
      }

      .col-12 {
        padding-left: 8px;
        padding-right: 8px;
      }

      .card {
        border-radius: 24px;
        box-shadow: 0 20px 40px 0 rgba(10, 13, 53, .08);
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .media-kit {
    h3 {
      font-size: $font-size-extra-large;
      line-height: 32px;
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1199px) {
  .media-kit {
    .favourite-allmyles-stories-section {
      .cards .col-12 {
        max-width: 352px;
      }
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .media-kit {
    .favourite-allmyles-stories-section {
      margin-bottom: 12px;

      .card {
        padding: 40px;
        height: 192px;

        img {
          height: 40px;
        }
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .media-kit {

    h1,
    h3 {
      text-align: center;
    }

    h1 {
      font-size: 60px;
      line-height: 72px;
    }
  }
}

@media screen and (max-width: 767px) {
  .media-kit {
    h1 {
      font-size: $font-size-extreme-large;
      line-height: 32px;
    }

    .favourite-allmyles-stories,
    .describing-allmyles {
      &-section {
        h2 {
          font-size: $font-size-extreme-large;
          line-height: 32px;
        }
      }
    }

    .describing-allmyles-section {
      h2 {
        margin-bottom: 20px;
      }
    }

    .brand-assets,
    .photo-assets {
      &-section {
        p {
          margin-bottom: 24px;
        }
      }
    }

    .favourite-allmyles-stories-section {
      margin-bottom: 48px;

      .col-12 {
        max-width: 272px;
      }

      .card {
        padding: 16px;
        height: 160px;

        img {
          height: 32px;
        }
      }
    }

    .describing-allmyles-section {
      margin-bottom: 56px;
    }

    .brand-assets-section {
      margin-bottom: 104px;

      .col-12 {
        margin-bottom: 16px;
      }
    }

    .photo-assets-section {
      margin-bottom: 40px;

      img {
        margin-bottom: 8px;
      }
    }
  }
}