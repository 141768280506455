.email-signature {
  tbody {
    vertical-align: initial;
  }

  td {
    padding: 0;
  }

  .logo {
    margin-bottom: 3px;
    width: 95px;
  }

  h5,
  h6 {
    margin-bottom: 5px;
    font-family: Aeonik Medium;
    font-size: 15px;
    line-height: 18px;
  }

  h5 {
    color: $thunder;
  }

  h6 {
    color: $regent-gray;
  }

  .social-media-links {
    margin-left: 24px;
  }

  .social-media-links img {
    margin-right: 8px;
    width: 12px;
  }

  a {
    color: $regent-gray;
    font-size: 12px;
    line-height: 24px;

    &:active,
    &:focus,
    &:hover {
      color: $black;
    }
  }
}