.features {
  h2 {
    margin-bottom: 0;
  }

  h3 {
    margin-bottom: 20px;
    font-size: $font-size-extreme-large;
    line-height: 32px;
  }

  p {
    margin-bottom: 16px;
    color: $black;
  }

  a {
    color: $royal-blue;
    font-family: Aeonik Medium;
    font-size: 18px;
    line-height: 24px;
  }

  .bookable,
  .payment-clutch,
  .boosted,
  .dashboard,
  .travel,
  .book {
    &-section {
      .row {
        align-items: center;
      }

      h2 {
        color: $royal-blue;
      }

      p {
        max-width: 397px;

        &:nth-child(4) {
          display: none;
        }
      }

      .col-12:first-child {
        text-align: center;
      }
    }
  }

  .online-travel-merchant-section p {
    margin: 0 auto 27px auto;
  }

  .travel-content-integration-section {
    h2 {
      margin-bottom: 43px;
    }

    .col-12 {
      display: flex;
      flex-direction: row;
      margin-bottom: 16px;
    }
  }

  .customers-service-shine {
    &-section {
      position: relative;
    }

    &-content {
      h2 {
        margin-bottom: 34px;
      }

      .col-12 {
        display: flex;
        flex-direction: row;
        margin-bottom: 31px;
      }
    }

    &-image {
      position: initial;
      text-align: center;

      img {
        margin: 0 !important;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  .bg--color-royal-blue {
    background: $royal-blue;
    color: $white;
    border-radius: 12px;
    opacity: .8;

    h2 {
      color: $white;
    }

    img {
      margin-right: 16px;
      width: 24px;
      height: 24px;
    }

    div {
      font-size: $font-size-large;
      line-height: 24px;
    }
  }
}

@media screen and (min-width: 992px) {
  .features {
    .bookable-section {
      margin-bottom: 82px;

      .col-12:last-child {
        padding-left: 110px;
      }
    }

    .payment-clutch-section {
      margin: 0 0 126px 0;

      .col-12:first-child {
        padding-right: 130px;
      }
    }

    .boosted-section {
      margin: 0 0 120px 0;

      .col-12:last-child {
        padding-left: 105px;
      }
    }

    .dashboard-section {
      margin: 144px 0 148px 0;

      .col-12:last-child {
        padding-left: 130px;
      }
    }

    .travel-section {
      margin: 0 0 113px 0;

      .col-12:first-child {
        padding-right: 120px;
      }
    }

    .book-section {
      margin: 0 0 116px 0;

      .col-12:last-child {
        padding-left: 100px;
      }
    }

    .online-travel-merchant-section {
      margin: 167px 0 184px 0;

      p {
        max-width: 608px;
      }
    }

    .travel-content-integration-section .container {
      padding: 84px 105px 65px 105px;
    }

    .customers-service-shine {
      &-section .container {
        padding: 76px 105px 44px 105px;
      }

      &-image img {
        width: 330px !important;
        height: 298px !important;
      }
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1199px) {
  .features {
    .bookable-section img {
      max-width: 226px;
    }

    .payment-clutch-section img {
      max-width: 322px;
    }

    .boosted-section img {
      max-width: 295px;
    }

    .dashboard-section img {
      max-width: 295px;
    }

    .travel-section img {
      max-width: 283px;
    }

    .book-section img {
      max-width: 295px;
    }

    .customers-service-shine-image img {
      width: 198px !important;
      height: 179px !important;
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .features {
    .bookable-section {
      margin-bottom: 95px;
    }

    .payment-clutch-section {
      margin: 0 0 101px 0;
    }

    .boosted-section {
      margin: 0 0 90px 0;
    }

    .dashboard-section {
      margin: 120px 0 148px 0;
    }

    .travel-section {
      margin: 0 0 100px 0;
    }

    .book-section {
      margin: 0 0 90px 0;
    }

    .online-travel-merchant-section {
      margin: 110px 0 126px 0;

      p {
        max-width: 475px;
      }
    }

    .travel-content-integration-section .container {
      padding: 72px 24px 50px 24px;
      max-width: 100%;
      border-radius: 0;
    }

    .customers-service-shine {
      &-section .container {
        padding: 75px 24px 44px 24px;
        max-width: 100%;
        border-radius: 0;
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .features {
    .online-travel-merchant-section {
      text-align: center;

      h2 {
        margin: 0 auto 38px auto;
        width: 500px;
        font-size: $font-size-mega-large;
        line-height: 56px;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .features {
    h2 {
      font-size: $font-size-extreme-large;
    }

    .bookable,
    .payment-clutch,
    .boosted,
    .dashboard,
    .travel,
    .book {
      &-section {
        margin: 0 0 50px 0;
      }
    }

    .bookable-section img {
      margin-bottom: 14px;
      max-width: 203px;
    }

    .payment-clutch-section img {
      margin-bottom: 34px;
      max-width: 290px;
    }

    .boosted-section img {
      margin-bottom: 16px;
      max-width: 266px;
    }

    .dashboard-section {
      margin-top: 71px;

      img {
        margin-bottom: 35px;
        max-width: 266px;
      }
    }

    .travel-section img {
      margin-bottom: 26px;
      max-width: 255px;
    }

    .book-section img {
      margin-bottom: 30px;
      max-width: 263px;
    }

    .online-travel-merchant-section {
      margin: 76px 0 61px 0;

      h2 {
        margin-bottom: 20px;
      }

      p {
        margin: 0 0 27px 0;
      }
    }

    .travel-content-integration-section .container {
      padding: 46px 16px 26px 16px;
      border-radius: 0;

      h2 {
        margin-bottom: 30px;
      }

      .col-12 {
        margin-bottom: 24px;
      }
    }

    .customers-service-shine {
      &-section .container {
        padding: 50px 16px 26px 16px;
        border-radius: 0;
      }

      &-content {
        h2 {
          margin-bottom: 26px;
        }

        .col-12 {
          margin-bottom: 24px;
        }
      }
    }
  }
}