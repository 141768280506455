@import './../base/fonts';

$aeonik-font-path: './../../assets/fonts/Aeonik' !default;

@font-face {
  font-family: Aeonik Regular;
  src: url('#{$aeonik-font-path}/Aeonik-Regular.otf') format('opentype');
  font-display: swap;
}

@font-face {
  font-family: Aeonik Medium;
  src: url('#{$aeonik-font-path}/Aeonik-Medium.otf') format('opentype');
  font-display: swap;
}

@font-face {
  font-family: Aeonik Light;
  src: url('#{$aeonik-font-path}/Aeonik-Light.otf') format('opentype');
  font-display: swap;
}

@font-face {
  font-family: Aeonik Bold;
  src: url('#{$aeonik-font-path}/Aeonik-Bold.otf') format('opentype');
  font-display: swap;
}