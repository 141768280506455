article {

  .post-header,
  .post-content {
    margin: 0 auto 40px auto;
    max-width: 608px;
  }

  .post-footer {
    text-align: center;
  }

  h3 {
    font-family: Aeonik Regular;
  }

  small {
    display: inline-block;
    margin-bottom: 8px;
    color: $regent-gray;
    font-size: $font-size-small;
    line-height: 24px;
  }

  img {
    margin-bottom: 40px;
    width: 100%;
    height: 320px;
    border-radius: 6px;
    object-fit: cover;
  }

  p {
    margin-bottom: 40px;
    color: $black;
  }

  span {
    margin-bottom: 16px;
    padding: 4px 14px;
    color: $white;
    background-color: $royal-blue;
    border-radius: 8px;
    font-family: Aeonik Medium;
    font-size: $font-size-small;
    line-height: 20px;
  }

  .post-header span {
    margin-right: 8px;
  }

  ul {
    padding: 0;
    margin: 16px auto 32px auto;
    font-size: $font-size-large;
    list-style: none;
  }
}

@media screen and (min-width: 768px) {
  article {
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 767px) {
  article {
    margin-bottom: 40px;

    img {
      height: 163px;
    }
  }
}